
import { Alert, Button, Col, Form, Input, InputNumber, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useSelector } from 'react-redux';


function Sparepart() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [load, setLoad] = useState(0);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Sparepart Servis';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Sparepart',
            dataIndex: 'SparepartCode',
            key: "SparepartCode",
            sorter: true,
        },
        {
            title: 'Serial Number',
            dataIndex: 'SerialNumber',
            key: "SerialNumber",
            sorter: true,
        },
        {
            title: 'Nama Sparepart',
            dataIndex: 'Name',
            key: "Name",
            sorter: true,
        },
        {
            title: 'Harga',
            dataIndex: 'Price',
            key: "Price",
            sorter: true,
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: "Description",
            sorter: true,
        },
        {
            title: 'Nama Toko',
            dataIndex: 'SparepartStore',
            key: "SparepartStore",
            sorter: true,
        },
        {
            title: 'Alamat Toko',
            dataIndex: 'SparepartAddress',
            key: "SparepartAddress",
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/sparepart?search=' + search + '&page=' + page.pagination.current, 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, load]);

    const getApi = async (method, url, type, data = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.rows;
                        setData(item);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'insert':
                        setTextAlert(res.message);
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    case 'update':
                        setTextAlert(res.message);
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    case 'del':
                        setTextAlert(res.message);
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                    default:
                        setTextAlert(res.message);
                        setIsAlert(true);
                        closeModal();
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setLoad(load + 1);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/sparepart/' + v.SparepartId, 'del'),
        });
    }

    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                "SparepartCode": data.SparepartCode,
                "SerialNumber": data.SerialNumber,
                "Name": data.Name,
                "Price": data.Price,
                "Description": data.Description,
                "SparepartStore": data.SparepartStore,
                "SparepartAddress": data.SparepartAddress,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            getApi('PUT', '/sparepart/' + dataModal.SparepartId, 'update', ev);
        } else {
            getApi('POST', '/sparepart', 'insert', ev);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Sparepart</h3>
            <Row style={{ marginBottom: 10 }}>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Sparepart" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="SparepartCode"
                        label="Kode Sparepart"
                        rules={[
                            {
                                required: true,
                                message: 'Kode Sparepart kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="SerialNumber"
                        label="Serial Number"
                        rules={[
                            {
                                required: true,
                                message: 'Serial Number kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="Name"
                        label="Nama Sparepart"
                        rules={[
                            {
                                required: true,
                                message: 'Name kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="Price"
                        label="Harga Sparepart"
                        rules={[
                            {
                                required: true,
                                message: 'Harga kosong!'
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: "100%"
                            }}
                            addonBefore="Rp."
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="Description"
                        label="Deskripsi"
                        rules={[
                            {
                                required: true,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="SparepartStore"
                        label="Nama Toko"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Toko kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="SparepartAddress"
                        label="Alamat Toko Sparepart"
                        rules={[
                            {
                                required: true,
                                message: 'Alamat Toko kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default Sparepart;