
import { Alert, Button, Col, DatePicker, Form, Image, Modal, Radio, Row, Select, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import { modalError } from '../../Component/Modal/ModalLayout';
import { currencyFormat } from '../../Utils/formatCurrency';


function ListFlashSaleProduct() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(0);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Flash Sale Set Periode';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Produk',
            dataIndex: 'ProductName',
            key: "ProductName",
            sorter: true,
        },
        {
            title: 'Harga Produk',
            dataIndex: 'Price',
            key: "Price",
            sorter: true,
            render: (text, record) => {
                return (<p>{currencyFormat(Number(record.Price))}</p>);
            }
        },
        {
            title: 'Harga Setelah Diskon',
            dataIndex: 'DiscountEndPrice',
            key: "DiscountEndPrice",
            sorter: true,
            render: (text, record) => {
                return (<p>{currencyFormat(Number(record.DiscountEndPrice))}</p>);
            }
        },
        {
            title: 'Kuota Diskon',
            dataIndex: 'DiscountKuota',
            key: "DiscountKuota",
            sorter: true,
        },
        {
            title: 'Terjual',
            dataIndex: 'Sold',
            key: "Sold",
            sorter: true,
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'CreatedAt',
            key: "CreatedAt",
            sorter: true,
            render: (text, record) => {
                if (record.CreatedAt !== null)
                    return (<p>{moment(record.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'UpdatedAt',
            key: "UpdatedAt",
            sorter: true,
            render: (text, record) => {
                if (record.UpdatedAt !== null)
                    return (<p>{moment(record.UpdatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formFlashsaleproduct', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/flashsaleproduct', 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);

    const getApi = (method, url, type, data = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.rows;
                        setData(item);
                        setLoading(false);
                        break;
                    case 'del':
                        setLoad(load + 1);
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/flashsaleproduct/' + v.FlashSaleId, 'del'),
        });
    }



    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Flash Sale Produk</h3>
            <Row>
                <Col span={8}>
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() =>  navigate('/formFlashsaleproduct')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Flash Sale Produk</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                loading={loading}
            />
        </>
    );
}

export default ListFlashSaleProduct;