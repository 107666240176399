
import { Alert, Card, Col, Divider, Row, Statistic, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { TeamOutlined, InboxOutlined, IdcardOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';


function Dashboard() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [search, setSearch] = useState('');
    const [load, setLoad] = useState(0);

    //statistic
    const [membershipCount, setMembershipCount] = useState(0);
    const [employeeCount, setEmployeeCount] = useState(0);
    const [productCount, setProductCount] = useState(0);
    // //Parameter
    const titlePage = 'Toko';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return index },
        },
        {
            title: 'Nama User',
            dataIndex: 'UserName',
            key: "UserName",
            sorter: true,
            render: (id, record, index) => { return record.user.name },
        },
        {
            title: 'Posisi',
            dataIndex: 'Position',
            key: "Position",
            sorter: true,
            render: (id, record, index) => { return record.user.MS_Role.Roles },
        },
        {
            title: 'Menu',
            dataIndex: 'Menu',
            key: "Menu",
            sorter: true,
        },
        {
            title: 'Deskripsi',
            dataIndex: 'Desc',
            key: "Desc",
            sorter: true,
        },
        {
            title: 'Tanggal & Waktu',
            dataIndex: 'Date',
            key: "Date",
            sorter: true,
            render: (text, record) => {
                return (<p>{moment(record.Date).format('YYYY-MM-DD HH:mm:ss')}</p>);
            }
        },
    ];

    useEffect(() => {
        getApi('GET', '/historyuser?search=' + search, 'history');
        getApi('GET', '/statistic', 'statistic');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAlert, load]);

    const getApi = (method, url, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case "history":
                        var item = res.data;
                        setData(item);
                        setLoading(false);
                        break;
                    case "statistic":
                        var item = res.data;
                        setMembershipCount(item.total_membership);
                        setEmployeeCount(item.total_employee);
                        setProductCount(item.total_product);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const searchData = (value) => {
        setSearch(value);
        setLoad(load + 1);
    }
    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title="Membership"
                            value={membershipCount}
                            prefix={<IdcardOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title="Produk"
                            value={productCount}
                            prefix={<InboxOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title="Karyawan"
                            value={employeeCount}
                            prefix={<TeamOutlined />}
                        />
                    </Card>
                </Col>
            </Row>

            <Divider />

            <h3>Aktivitas User</h3>
            <Row style={{ marginBottom: 10 }}>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >

                </Col>
            </Row>
            {/* <Table
                size="middle"
                columns={columns}
                dataSource={data}
                // pagination={page.pagination}
                loading={loading}
            /> */}
        </>
    );
}

export default Dashboard;