import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

function FormFlashSaleProduct() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(250);
    const [pageInfo, setPageInfo] = useState('');
    //product
    const [product, setProduct] = useState([]);
    const [time, setTime] = useState([]);
    const [variant, setVariant] = useState([]);
    const [arrInputDate, setArrInputDate] = useState([]);
    const [arrInputTime, setArrInputTime] = useState([{ label: '', value: '' }]);

    const save = (ev) => {
        const state = location.state;
        var method = 'POST';
        var arr_times = [];
        var arr_dates = [];
        for (let i = 0; i < arrInputDate.length; i++) {
            arr_dates.push(arrInputDate[i].value);
        }
        for (let i = 0; i < arrInputTime.length; i++) {
            arr_times.push(arrInputTime[i].value);
        }
        var arr_product = JSON.parse(ev.ProductId);
        var arr_variant = JSON.parse(ev.VariantId);
        ev.FlashDate = arr_dates;
        ev.FlashTimeId = arr_times;

        //product
        ev.MiniDesc = arr_product.body_html;
        ev.ProductName = arr_product.title;
        ev.Description = arr_product.body_html;
        ev.Images = arr_product.image.src;
        ev.ProductId = arr_product.id;

        //variant
        ev.BeratProduk = arr_variant.weight;
        ev.DescriptionVariant = arr_variant.title;
        ev.VariantId = arr_variant.id;

        ev.IsActive = true;
        //params
        if (state != null) {
            ev.FlashSaleId = state.item.FlashSaleId;
            method = "PUT";
        } else {
            method = "POST";
        }
        postApi(method, ev);
    }

    const postApi = (method, values) => {
        const dataReq = {
            method: method,
            url: '/flashsaleproduct',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getApi('GET', '/product?limit=250', 'product');
        getApi('GET', '/flashsaletimenopage', 'time');
        if (location.state != null) {
            setArrInputDate([]);
            const item = location.state.item;
            form.setFieldsValue({
                Price: item.Price,
                DiscountPercent: item.DiscountPercent,
                DiscountAmount: item.DiscountAmount,
                DiscountEndPrice: item.DiscountEndPrice,
                DiscountKuota: item.DiscountKuota,
            })
            var row_date = [];
            var row_time = [];
            for (let i = 0; i < item.FlashSalePeriods.length; i++) {
                row_date.push({
                    label: dayjs(item.FlashSalePeriods[i].FlashSaleDate),
                    value: item.FlashSalePeriods[i].FlashSaleDate
                })
            }
            for (let i = 0; i < item.FlashSalePeriodTimes.length; i++) {
                row_time.push({
                    label: item.FlashSalePeriodTimes[i].MS_PeriodF.Start + ' - ' + item.FlashSalePeriodTimes[i].MS_PeriodF.End,
                    value: item.FlashSalePeriodTimes[i].PeriodFSId
                })
            }
            getApi('GET', '/product/'+item.ProductId, 'product_by_id');
            setArrInputDate(row_date);
            setArrInputTime(row_time);
        }else{
            setArrInputDate([{ label: '', value: '' }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageInfo]);

    const getApi = async (method, url, type) => {
        var query = ''
        if (pageInfo !== '' && type == 'product') {
            query = '&page_info=' + pageInfo
        }
        const dataReq = {
            method: method,
            url: url + query,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'product':
                        var item = res.data.products;
                        setCount(count + 250);
                        var row = [];
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: JSON.stringify(item[i]),
                                label: item[i].title,
                            });
                        }
                        let setDatas = [].concat(product, row);
                        setProduct(setDatas);
                        if (res.count >= count) {
                            setPageInfo(res.page_info);
                        } else {
                            setLoading(false);
                        }
                        break;
                    case 'time':
                        var item_time = res.data;
                        var row = [];
                        for (let i = 0; i < item_time.length; i++) {
                            row.push({
                                value: item_time[i].PeriodFSId,
                                label: item_time[i].Start + ' - ' + item_time[i].End,
                            });
                        }
                        setTime(row);
                        break;
                    case 'product_by_id':
                        const data = location.state.item;
                        var item_product = res.data.product;
                        var item_variant;
                        var row = [];
                        for (let i = 0; i < item_product.variants.length; i++) {
                            row.push({
                                value: JSON.stringify(item_product.variants[i]),
                                label: item_product.variants[i].title,
                            });
                            if(item_product.variants[i].id === Number(data.VariantId)){
                                item_variant = item_product.variants[i];
                            }
                        }
                        setVariant(row);
                        form.setFieldsValue({
                            ProductId: JSON.stringify(item_product),
                            VariantId: JSON.stringify(item_variant),
                        })
                        break;
                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const selectData = (event, type) => {
        var json_arr = JSON.parse(event);
        var row = [];
        if (type === 'product') {
            form.setFieldsValue({
                VariantId: null,
                Price: null,
            })
            for (let i = 0; i < json_arr.variants.length; i++) {
                row.push({
                    value: JSON.stringify(json_arr.variants[i]),
                    label: json_arr.variants[i].title,
                });
            }
            setVariant(row);
        } else {
            form.setFieldValue('Price', Number(json_arr.price).toString());
        }
    }

    const discountEnd = (text) => {
        var disc_percent = Number(text) / 100;
        var price = form.getFieldValue('Price');
        var price_end = price * disc_percent;
        form.setFieldsValue({
            DiscountAmount: Number(price_end).toString(),
            DiscountEndPrice: Number(price - price_end).toString()
        })
    }

    const updateArr = (arr, key, value, valueString) => {
        arr[key].value = valueString;
        arr[key].label = value;
    }

    const updateTime = (arr, value, key) => {
        arr[key].value = value;
        arr[key].label = value;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={form.submit} size='large' >
                    <b style={{ marginLeft: 5 }}>Simpan</b>
                </Button>
            </div>
            <Form form={form} name="form_product" layout="vertical" onFinish={save}>
                <Form.Item
                    name="ProductId"
                    label="Pilih Produk"
                    rules={[
                        {
                            required: true,
                            message: 'Pilih Produk kosong!'
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder="Pilih Produk"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        onChange={(ev) => selectData(ev, 'product')}
                        options={product}
                    />
                </Form.Item>
                {
                    variant !== null &&
                    <Form.Item
                        name="VariantId"
                        label="Pilih Varian"
                        rules={[
                            {
                                required: true,
                                message: 'Pilih Varian kosong!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Varian"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={(ev) => selectData(ev, 'variant')}
                            options={variant}
                        />
                    </Form.Item>
                }
                <Form.Item
                    name="Price"
                    label="Harga Awal"
                    rules={[
                        {
                            required: true,
                            message: 'Harga Awal kosong!'
                        },
                    ]}

                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    name="DiscountPercent"
                    label="Presentasi Diskon (%)"
                    rules={[
                        {
                            required: true,
                            message: 'Presentasi Diskon (%) kosong!'
                        },
                    ]}
                >
                    <Input onChange={(ev) => discountEnd(ev.target.value)} maxLength={2} />
                </Form.Item>
                <Form.Item
                    name="DiscountAmount"
                    label="Total Diskon"
                    rules={[
                        {
                            required: true,
                            message: 'Total Diskon kosong!'
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item
                    name="DiscountEndPrice"
                    label="Harga Akhir"
                    rules={[
                        {
                            required: true,
                            message: 'Harga Akhir kosong!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="DiscountKuota"
                    label="Kouta"
                    rules={[
                        {
                            required: true,
                            message: 'Kouta kosong!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {/* <Form.Item
                    name="FlashDate"
                    label="Tanggal FlashSale"
                    rules={[
                        {
                            required: true,
                            message: 'Tanggal FlashSale kosong!'
                        },
                    ]}
                >
                    <Input />
                </Form.Item> */}
                <div>
                    <Row>
                        <Col>
                            {
                                arrInputDate.map((key, i) => (
                                    <Form.Item
                                        key={i}
                                        label={"Tanggal FlashSale " + (i + 1).toString()}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tanggal FlashSale kosong!'
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder='Pilih Tanggal'
                                            style={{ width: 200 }}
                                            picker='date'
                                            onChange={(date, dateString) => updateArr(arrInputDate, i, date, dateString)}
                                            defaultValue={key.label}
                                            />
                                    </Form.Item>
                                ))
                            }
                        </Col>
                        <Col>
                            <Button onClick={() => setArrInputDate([...arrInputDate, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                            <Button onClick={() => setArrInputDate(arrInputDate.splice(0, arrInputDate.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                arrInputTime.map((key, i) => (
                                    <Form.Item
                                        key={i}
                                        label={"Waktu " + (i + 1).toString()}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Waktu kosong!'
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: 200 }}
                                            showSearch
                                            placeholder="Pilih Periode"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            value={key.value}
                                            onChange={(ev) => updateTime(arrInputTime, ev, i)}
                                            options={time}
                                        />
                                    </Form.Item>
                                ))
                            }
                        </Col>
                        <Col>
                            <Button onClick={() => setArrInputTime([...arrInputTime, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                            <Button onClick={() => setArrInputTime(arrInputTime.splice(0, arrInputTime.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}

export default FormFlashSaleProduct;