import { Button, Col, DatePicker, Form, Image, Input, Radio, Row, TreeSelect, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import  dayjs  from 'dayjs';
const { SHOW_PARENT } = TreeSelect;
const { RangePicker } = DatePicker;

function FormStorePromo() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [file, setFile] = useState();
    const dateFormat = 'YYYY-MM-DD';
    //store
    const [store, setStore] = useState();

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadImage = (info) => {
        setFile(info.file);
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const save = (ev) => {
        const state = location.state;
        var method = 'POST';
        //params
        if (imageUrl != null) {
            var rangeDate = ev.DateStartEnd;
            var startDate = rangeDate[0].format(dateFormat);
            var endDate = rangeDate[1].format(dateFormat);
            ev.PeriodStart = startDate;
            ev.PeriodEnd = endDate;
            ev.StoreId = JSON.stringify(ev.StoreId);
            delete ev.DateStartEnd;
            if (file != null) {
                ev.PromoImage = file;
                if (state != null)
                    ev.OldPromoImage = state.item.PromoImage;
            }
            if (state != null) {
                ev.StorePromoId = state.item.StorePromoId;
                method = "PUT";
            } else {
                method = "POST";
            }
            const form = new FormData();
            for (const name in ev) {
                form.append(name, ev[name]);
            }
            postApi(method, form);
        } else {
            modalError('Gambar belum diupload');
        }
    }

    const postApi = (method, values) => {
        const dataReq = {
            method: method,
            url: '/storepromo',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            var row = [];
            for (let i = 0; i < item.PromoForStores.length; i++) {
                row.push(item.PromoForStores[i].StoreId);
            }
            var DateStartEnd = [dayjs(item.PeriodStart), dayjs(item.PeriodEnd)];
            form.setFieldsValue({
                PromoName: item.PromoName,
                StoreId: row,
                DateStartEnd: DateStartEnd,
                IsPrimary: item.IsPrimary,
                TnCPromo: item.TnCPromo
            })
            if (item.image !== null) {
                setImageUrl(item.PromoImage);
            }
        }
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = () => {
        const dataReq = {
            method: "GET",
            url: '/storenopage',
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var row = [];
                for (let i = 0; i < res.data.length; i++) {
                    row.push({
                        value: res.data[i].StoreId,
                        label: res.data[i].StoreName,
                    });
                }
                setStore(row);
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={form.submit} size='large' >
                    <b style={{ marginLeft: 5 }}>Simpan</b>
                </Button>
            </div>
            <Form form={form} name="form_store" layout="vertical" onFinish={save}>
                <Row>
                    <Col span={16}>
                        <Form.Item
                            name="PromoName"
                            label="Nama Promo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Promo kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="StoreId"
                            label="Toko"
                            rules={[
                                {
                                    required: true,
                                    message: 'Toko kosong!'
                                },
                            ]}
                        >
                            <TreeSelect
                                treeCheckable={true}
                                showCheckedStrategy={SHOW_PARENT}
                                treeData={store}
                            />
                        </Form.Item>
                        <Form.Item
                            name="DateStartEnd"
                            label="Masa Berlaku"
                            rules={[
                                {
                                    required: true,
                                    message: 'Masa Berlaku kosong!'
                                },
                            ]}
                            
                        >
                            <RangePicker format={dateFormat} />
                        </Form.Item>
                        <Form.Item
                            name="TnCPromo"
                            label="Syarat Ketentuan Promo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Syarat Ketentuan Promo kosong!'
                                },
                            ]}
                        >
                            <Input.TextArea autoSize={true} />
                        </Form.Item>
                        <Form.Item
                            name="IsPrimary"
                            label="Primary"
                            rules={[
                                {
                                    required: true,
                                    message: 'Primary kosong!'
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={true} defaultChecked={true} >Iya</Radio>
                                <Radio value={false}>Tidak</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }} >
                        <p>Upload Gambar</p>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            onChange={uploadImage}
                            beforeUpload={() => false}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{ height: 200, width: 200 }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default FormStorePromo;