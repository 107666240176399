import { Button, Col, Form, Input, Radio, Row, Select, TreeSelect, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const { SHOW_PARENT } = TreeSelect;

function FormCategory() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [product, setProduct] = useState([]);
    const sortOrder = [
        {
            value: 'alpha-asc',
            label: 'alpha-asc (Alphabetically, in ascending order (A - Z))',
        },
        {
            value: 'alpha-desc',
            label: 'alpha-desc (Alphabetically, in descending order (Z - A))',
        },
        {
            value: 'best-selling',
            label: 'best-selling (By best-selling products)',
        },
        {
            value: 'created',
            label: 'created (By date created, in ascending order (oldest - newest))',
        },
        {
            value: 'created-desc',
            label: 'created-desc (By date created, in descending order (newest - oldest))',
        },
        {
            value: 'manual',
            label: 'manual (Order created by the shop owner)',
        },
        {
            value: 'price-asc',
            label: 'price-asc (By price, in ascending order (lowest - highest))',
        },
        {
            value: 'price-desc',
            label: 'price-desc (By price, in descending order (highest - lowest))',
        },
    ]

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadImage = (info) => {
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const save = (ev) => {
        const state = location.state;
        ev.image = {
            attachment:imageUrl,
            alt:ev.Title
        }
        Api('POST','/category', 'category', ev);
    }

    const Api = (method, url, type, values = null) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                if (type === 'product') {
                    var row = [];
                    var item = res.data.products;
                    for (let i = 0; i < item.length; i++) {
                        row.push({
                            value: item[i].id,
                            label: item[i].title,
                        })
                    }
                    setProduct(row);
                } else if (type === 'category') {
                    navigate(-1);
                }
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            form.setFieldsValue({
                Title:item.title,
                Description:item.body_html,
                SortOrder:item.sort_order,
                // ProductId:,
                IsPublished:item.published_at !== null ? true : false
            });

            if (item.image !== undefined) {
                setImageUrl(item.image.src);
            }
        }
        Api('GET', '/product', 'product');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={form.submit} size='large' >
                        <b style={{ marginLeft: 5 }}>Simpan</b>
                    </Button>
                </div>
                <Form form={form} name="form_store" layout="vertical" onFinish={save}>
                    <Row>
                        <Col span={16}>
                            <Form.Item
                                name="Title"
                                label="Nama Kategori"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Kategori kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="Description"
                                label="Deskripsi"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi kosong!'
                                    },
                                ]}
                            >
                                {/* <Input.TextArea autoSize={true} /> */}
                                <ReactQuill theme="snow" />
                            </Form.Item>
                            <Form.Item
                                name="SortOrder"
                                label="Sort Order"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Sorting Order kosong!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Sort Order"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={sortOrder}
                                />
                            </Form.Item>
                            <Form.Item
                                name="ProductId"
                                label="Produk"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pilih produk terlebih dahulu!'
                                    },
                                ]}
                            >
                                <TreeSelect
                                    treeCheckable={true}
                                    showCheckedStrategy={SHOW_PARENT}
                                    treeData={product}
                                    
                                />
                            </Form.Item>
                            <Form.Item
                                name="IsPublished"
                                label="Publish"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Pilih Publish terlebih dahulu!'
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true} defaultChecked={true} >Aktif</Radio>
                                    <Radio value={false}>Tidak Aktif</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }} >
                            <p>Upload Gambar</p>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                onChange={uploadImage}
                                beforeUpload={() => false}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ height: 200, width: 200 }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default FormCategory;