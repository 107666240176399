
const UPDATE_TOKEN = (token) => {
    return { type: 'UPDATE_TOKEN', token }
}
const UPDATE_USER = (user) => {
    return { type: 'UPDATE_USER', user }
}

export { 
    UPDATE_TOKEN,
    UPDATE_USER
}