
import { Alert, Button, Col, DatePicker, Form, Image, Modal, Radio, Row, Select, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import dayjs from 'dayjs';
import { modalError } from '../../Component/Modal/ModalLayout';


function ListFlashSaleTime() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [load, setLoad] = useState(0);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Flash Sale Set Periode';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: true,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Jam Publish',
            dataIndex: 'MS_PeriodF',
            key: "MS_PeriodF",
            sorter: true,
            render: (text, record) => {
                if (record.MS_PeriodF !== null)
                    return (<p>{record.Start} - {record.End}</p>);
                else
                    return null;
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'CreatedAt',
            key: "CreatedAt",
            sorter: true,
            render: (text, record) => {
                if (record.CreatedAt !== null)
                    return (<p>{moment(record.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'UpdatedAt',
            key: "UpdatedAt",
            sorter: true,
            render: (text, record) => {
                if (record.UpdatedAt !== null)
                    return (<p>{moment(record.UpdatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/flashsaletime', 'all');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);

    const getApi = (method, url, type, data = null) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.rows;
                        setData(item);
                        setLoading(false);
                        break;
                    case 'insert':
                        setLoading(false);
                        setIsAlert(true);
                        setTextAlert(res.message);
                        closeModal();
                        setLoad(load + 1);
                        break;
                    case 'update':
                        setLoading(false);
                        setIsAlert(true);
                        setTextAlert(res.message);
                        closeModal();
                        setLoad(load + 1);
                        break;
                    case 'del':
                        setLoad(load + 1);
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/flashsaletime/' + v.PeriodFSId, 'del'),
        });
    }

    const openModal = (type, data = null) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                Start: dayjs(data.Start,'HH:mm:ss'),
                End: dayjs(data.End,'HH:mm:ss'),
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const onFinish = (ev) => {
        ev.Start = ev.Start.format('HH:mm:ss');
        ev.End = ev.End.format('HH:mm:ss');
        if (dataModal != null) {
            ev.PeriodFSId = dataModal.PeriodFSId;
            getApi('PUT', '/flashsaletime', 'update', ev);
        } else {
            getApi('POST', '/flashsaletime', 'insert', ev);
        }
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Flash Sale Periode</h3>
            <Row>
                <Col span={8}>
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => openModal('add')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Periode</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                // pagination={page.pagination}
                loading={loading}
            // onChange={handleTableChange}
            />
            <Modal title="Form Flash Sale Set Periode" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="Start"
                        label="Jam Mulai"
                        rules={[
                            {
                                required: true,
                                message: 'Jam Mulai kosong!'
                            },
                        ]}
                    >
                        <DatePicker picker='time' style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                        name="End"
                        label="Jam Berakhir"
                        rules={[
                            {
                                required: true,
                                message: 'Jam Berakhir kosong!'
                            },
                        ]}
                    >
                        <DatePicker picker='time' style={{ width: "100%" }} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ListFlashSaleTime;