import { Button, Col, Divider, Image, Row, Steps, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { URL_DASHBOARD, apiCall } from '../../API/RestApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Content } from 'antd/es/layout/layout';
import { currencyFormat } from '../../Utils/formatCurrency';

function DetailOrder() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState();
    const [dataProcess, setDataProcess] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (location.state != null) {
            getApi('/order/' + location.state.item.OrderId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (url) => {
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var row = [];
                var item = res.data.TR_Payments;
                for (let i = 0; i < item.length; i++) {
                    row.push({ title: item[i].MS_BuyingStatus.BuyStatus, description: item[i].MS_BuyingStatus.Keterangan })
                }
                setDataProcess(row);
                setData(res.data);
                setLoading(false);
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const tx_title = {
        color: "#333",
        fontFamily: "sans-serif",
        marginLeft: 5,
        fontWeight: "bold"
    };

    const tx_cart = {
        color: "#455a64",
        fontFamily: "sans-serif",
        margin: 5
    };

    const tx = {
        color: "#455a64",
        fontFamily: "sans-serif",
        margin: 5
    };

    const tx_price = {
        color: "red",
        fontFamily: "sans-serif",
        margin: 5
    };


    return (
        <div>
            {
                data != null ?
                    <Content>
                        <Row>
                            <Col span={17}>
                                {
                                    data.OrderAlamats != null && data.OrderAlamats.length > 0 ?
                                        <div>
                                            <h3 style={tx_title}>Alamat Pengiriman</h3>
                                            <b style={tx}>{data.OrderAlamats[0].NamaDepan + ' ' + data.OrderAlamats[0].NamaBelakang}</b>
                                            <div style={tx}>
                                                {data.OrderAlamats[0].Alamat + ', '}
                                                {data.OrderAlamats[0].Kecamatan + ', '}
                                                {data.OrderAlamats[0].Kota + ', '}
                                                {data.OrderAlamats[0].Provinsi + ' - '}
                                                {data.OrderAlamats[0].KodePos}
                                            </div>
                                            <div style={tx}>Telp. {data.OrderAlamats[0].NoHandphone}</div>
                                        </div>
                                        :
                                        <div>
                                            <h3 style={tx_title}>Alamat Pengiriman</h3>
                                            <p style={tx}>Tidak ada alamat pengiriman</p>
                                        </div>
                                }

                                <Divider />
                                <h3 style={tx_title}>Daftar Produk</h3>
                                {
                                    data.OrderDetails.map((key, i) => (
                                        <Row key={i}>
                                            <Col>
                                                <Image src={key.Image} width={120} height={120} />
                                            </Col>
                                            <Col>
                                                <p style={tx}>{key.ProductName}</p>
                                                <p style={tx}>Varian : {key.DescriptionVariant}</p>
                                                <p style={tx}>Jumlah : {key.Qty}</p>
                                                <p style={tx_price}><b>{currencyFormat(key.Harga)} / Unit</b></p>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                <Divider />
                                <Row>
                                    <Col span={8}>
                                        <p>Dikirim oleh : Bolde Store</p>
                                    </Col>
                                    <Col span={8}>
                                        <p>Metode Pengiriman : {data.CourierCode} - {data.CourierService}</p>
                                    </Col>
                                    <Col span={8}>
                                        <p>Nomor Resi : <Tag>{data.Resi !== null ? data.Resi : 'Belum tersedia'}</Tag></p>
                                    </Col>
                                </Row>
                                <Divider />
                                <h3 style={tx_title}>Lacak Pesanan</h3>
                                <Steps
                                    progressDot
                                    current={3}
                                    direction="vertical"
                                    items={dataProcess}
                                />
                            </Col>
                            <Col span={0.5}>
                                <Divider type="vertical" style={{ height: "100%" }} />
                            </Col>
                            <Col span={6}>
                                <Button type='primary' style={{ marginRight: 10 }} onClick={() => window.location.replace(URL_DASHBOARD + '/order/downloadInvoiceOrder/' + data.OrderId)}>Download Invoice</Button>
                                {data.Resi && <Button type='primary' danger onClick={() => window.location.replace(URL_DASHBOARD + '/order/CetakResi/' + data.OrderId)}>Cetak Resi</Button>}
                                <h3 style={tx_title}>Detail Pesanan</h3>
                                <Row style={{ textAlign: "right" }}>
                                    <Col style={{ textAlign: "left" }}>
                                        <h4 style={tx_cart}>Total Harga</h4>
                                        <h4 style={tx_cart}>Ongkos Kirim</h4>
                                        <Divider style={{ margin: 5 }} />
                                        <strong style={tx_cart}>Total</strong>
                                    </Col>
                                    <Col>
                                        <h4 style={tx_cart}>:</h4>
                                        <h4 style={tx_cart}>:</h4>
                                        <Divider style={{ margin: 5 }} />
                                        <strong style={tx_cart}>:</strong>
                                    </Col>
                                    <Col>
                                        <h4 style={tx_cart}>{currencyFormat(data.SubTotal)}</h4>
                                        <h4 style={tx_cart}>{currencyFormat(data.ShippingCharge)}</h4>
                                        <Divider style={{ margin: 5 }} />
                                        <strong style={tx_price}>{currencyFormat(data.Total)}</strong>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                    :
                    null
            }
        </div>
    );

}

export default DetailOrder;