import { Button, Col, Form, Input, Radio, Row, Select, Upload } from 'antd';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function FormStore() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [file, setFile] = useState();
    const [latlong, setLatLong] = useState();
    const [selectMap, setSelectMap] = useState('maps');

    //LOCATIION
    const [province, setProvince] = useState([]);
    const [city, setCity] = useState([]);
    const [subdistrict, setSubdistrict] = useState([]);

    //LATLONG
    const [defaultProps, setDefaultProps] = useState({
        center: {
            address: 'Jakarta',
            lat: -6.2295695,
            lng: 106.7471169
        },
        zoom: 11
    });


    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadImage = (info) => {
        setFile(info.file);
        getBase64(info.file, (url) => {
            setImageUrl(url);
        });
    };

    const save = async (ev) => {
        const state = location.state;
        if (file != null) {
            ev.StoreImage = file;
        }
        ev.isClosed = false;
        if(ev.link !== null && ev.link !== undefined){
            var links = ev.link.split('/')[6];
            var split = links.split('@')[1].split(',');
            var lat = split[0];
            var lng = split[1];
            ev.Latitude = await lat.toString();
            ev.Longitude = await lng.toString();
        }else{
            if (latlong !== null && latlong !== undefined) {
                if (latlong.lat != null) {
                    ev.Latitude = latlong.lat;
                }
                if (latlong.lng != null) {
                    ev.Longitude = latlong.lng;
                }
            }
        }
        var method = 'POST';
        if (state != null) {
            ev.OldStoreImage = state.item.StoreImage;
            ev.StoreId = state.item.StoreId;
            method = "PUT";
        } else {
            method = "POST";
        }
        delete ev.link;
        delete ev.Maps;
        const form = new FormData();
        for (const name in ev) {
            form.append(name, ev[name]);
        }
        postApi(method, form);
    }

    const postApi = (method, values) => {
        const dataReq = {
            method: method,
            url: '/store',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            form.setFieldsValue({
                StoreCode: item.StoreCode,
                StoreName: item.StoreName,
                StoreDesc: item.StoreDesc,
                StoreAddress: item.StoreAddress,
                HariOperasional: item.HariOperasional,
                JamOperasional: item.JamOperasional,
                MS_ProvinceId: item.MS_ProvinceId,
                MS_KabupatenId: item.MS_KabupatenId,
                MS_KecamatanId: item.MS_KecamatanId,
                StatusToko: item.StatusToko
            });
            setLatLong({
                lat: item.Latitude,
                lng: item.Longitude
            })
            setDefaultProps({
                center: {
                    address: item.Location,
                    lat: item.Latitude,
                    lng: item.Longitude
                },
                zoom: 12
            })

            if (item.image !== null) {
                setImageUrl(item.StoreImage);
            }
        }
        getApi('/location/province', 'province');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (url, type) => {
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'province':
                        var row = [];
                        for (let i = 0; i < res.data.length; i++) {
                            row.push({
                                value: res.data[i].MS_ProvinceId,
                                label: res.data[i].Province,
                            });
                        }
                        setProvince(row);
                        break;
                    case 'city':
                        var row = [];
                        for (let i = 0; i < res.data.length; i++) {
                            row.push({
                                value: res.data[i].MS_KabupatenId,
                                label: res.data[i].Kabupaten,
                            });
                        }
                        setCity(row);
                        break;
                    case 'subdistrict':
                        var row = [];
                        for (let i = 0; i < res.data.length; i++) {
                            row.push({
                                value: res.data[i].MS_KecamatanId,
                                label: res.data[i].Kecamatan,
                            });
                        }
                        setSubdistrict(row);
                        break;

                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const getLocation = (id, type) => {
        if (type == 'province') {
            getApi('/location/city/' + id, 'city');
        } else if (type == 'city') {
            getApi('/location/subdistrict/' + id, 'subdistrict');
        }
    }

    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: {
                lat: -6.2295695,
                lng: 106.7471169
            },
            map,
            title: 'Hello World!',
        });
    }
    return (
        <div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={form.submit} size='large' >
                        <b style={{ marginLeft: 5 }}>Simpan</b>
                    </Button>
                </div>
                <Form form={form} name="form_store" layout="vertical" onFinish={save}>
                    <Row>
                        <Col span={16}>
                            <Form.Item
                                name="StoreCode"
                                label="Kode Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kode Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="StoreName"
                                label="Nama Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="StoreDesc"
                                label="Deskripsi Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deskripsi Toko kosong!'
                                    },
                                ]}
                            >
                                <Input.TextArea autoSize={true} />
                            </Form.Item>
                            <Form.Item
                                name="StoreAddress"
                                label="Alamat Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Alamat Toko kosong!'
                                    },
                                ]}
                            >
                                <Input.TextArea autoSize={true} />
                            </Form.Item>
                            <Form.Item
                                name="MS_ProvinceId"
                                label="Provinsi"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Provinsi kosong!!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Provinsi"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={province}
                                    onChange={(ev) => getLocation(ev, 'province')}
                                />
                            </Form.Item>
                            <Form.Item
                                name="MS_KabupatenId"
                                label="Kota"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kota kosong!!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Kota"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={city}
                                    onChange={(ev) => getLocation(ev, 'city')}
                                />
                            </Form.Item>
                            <Form.Item
                                name="MS_KecamatanId"
                                label="Kecamatan"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Kecamatan kosong!!'
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Pilih Kecamatan"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={subdistrict}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Radio.Group onChange={(e) => setSelectMap(e.target.value)}>
                                    <Radio value={'maps'} defaultChecked={true} >Lokasi dengan Maps</Radio>
                                    <Radio value={'link'}>Lokasi dengan Link</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {
                                selectMap === 'maps' ?
                                    <Form.Item
                                        name="Maps"
                                        label="Maps"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}>
                                        <div style={{ height: 500, width: 1000 }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "AIzaSyBrln8U4rwZKjcYaWfN2JMoScd-qvriyto" }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                            >
                                            </GoogleMapReact>
                                        </div>
                                    </Form.Item>
                                    :

                                    <Form.Item
                                        name="link"
                                        label="Link Maps"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Link Maps kosong!'
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Masukkan link maps disini' />
                                    </Form.Item>
                            }
                            <Form.Item
                                name="HariOperasional"
                                label="Hari Buka Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Hari Buka Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="JamOperasional"
                                label="Jam Buka Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jam Buka Toko kosong!'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="StatusToko"
                                label="Status Toko"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Status Toko kosong!'
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true} defaultChecked={true} >Aktif ( 365 )</Radio>
                                    <Radio value={false}>Pasif ( 760 )</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ textAlign: 'center' }} >
                            <p>Upload Gambar</p>
                            <Upload
                                listType="picture-card"
                                showUploadList={false}
                                onChange={uploadImage}
                                beforeUpload={() => false}
                            >
                                {imageUrl ? (
                                    <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{ height: 200, width: 200 }}
                                    />
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default FormStore;