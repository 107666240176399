import { Button, Col, Form, Image, Input, Radio, Row, Select, Tabs, Upload } from 'antd';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EnvironmentOutlined } from '@ant-design/icons';

function FormUsers() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const [roles, setRoles] = useState([]);

    const save = (ev) => {
        const state = location.state;
        if(ev.password === null || ev.password === undefined || ev.password === ''){
            delete ev['password'];
        }
        var method = 'POST';
        if (state != null) {
            ev.id = state.item.id;
            method = "PUT";
        } else {
            method = "POST";
        }
        console.log(method);
        console.log(ev);
        postApi(method, ev);
    }

    const postApi = (method, values) => {
        const dataReq = {
            method: method,
            url: '/user',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        console.log(dataReq);
        apiCall(dataReq).then((res) => {
            console.log(res)
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }


    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            form.setFieldsValue({
                name: item.name,
                mobile: item.mobile,
                email: item.email,
                NIKNumber: item.NIKNumber,
                RolesId: item.RolesId,
                Referal:item.Referal
            });
        }
        getApi('/role','role');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = (url, type) => {
        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'role':
                        var row = [];
                        for (let i = 0; i < res.data.length; i++) {
                            row.push({
                                value: res.data[i].RolesId,
                                label: res.data[i].Roles,
                            });
                        }
                        setRoles(row);
                        break;
                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }


    return (
        <div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" onClick={form.submit} size='large' >
                        <b style={{ marginLeft: 5 }}>Simpan</b>
                    </Button>
                </div>
                <Form form={form} name="form_user" layout="vertical" onFinish={save}>
                    <Form.Item
                        name="name"
                        label="Nama"
                        rules={[
                            {
                                required: true,
                                message: 'Nama kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={location.state != null ? "Ubah Password" : "Password"}
                        rules={[
                            {
                                required:location.state != null ? false : true ,
                                message: 'Password kosong!'
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Nomor Handphone"
                        rules={[
                            {
                                required: true,
                                message: '"Nomor Handphone kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                required: true,
                                message: 'E-mail kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="RolesId"
                        label="Hak Akses"
                        rules={[
                            {
                                required: true,
                                message: 'Hak Akses kosong!!'
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Hak Akses"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={roles}
                        />
                    </Form.Item>
                    {/* <Form.Item
                        name="Referal"
                        label="Kode Referal"
                        rules={[
                            {
                                required: false,
                                message: 'Kode Referal kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        name="NIKNumber"
                        label="Nomor NIK"
                        rules={[
                            {
                                required: true,
                                message: 'Nomor NIK kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default FormUsers;