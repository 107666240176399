
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import './App.css';
import { store } from './Redux/stores/store';
import Router from './Route/Router';
// import { socket } from './Utils/Socket';
// import { useEffect } from 'react';
// import runOneSignal from './Utils/onesignal';
// import OneSignalReact from 'react-onesignal';

const App = () => {

  // useEffect(() => {
  //   function onConnect() {
  //     console.log(true)
  //   }

  //   function onDisconnect() {
  //     console.log(false)
  //   }

  //   function onFooEvent(value) {
  //     console.log('masuk event')
  //   }

  //   socket.on('connect', onConnect);
  //   socket.on('disconnect', onDisconnect);
  //   socket.on('foo', onFooEvent);

  //   return () => {
  //     socket.off('connect', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     socket.off('foo', onFooEvent);
  //   };
  // }, []);
  // const count = useRef(null);
  // useEffect(() => {
  //   if (count.current == null) {
  //     runOneSignal();
  //   }
  //   getDataNotif();
  //   return () => { count.current = 1; }
  // }, []);

  // function getDataNotif (){
  //   OneSignalReact.addListenerForNotificationOpened().then((res) => console.log(res)).catch((e) => console.log(e));
  // }

  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <Router />
        </Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
