import { HomeOutlined, BookOutlined, TeamOutlined, SettingOutlined, ToolOutlined, TagsOutlined, ShopOutlined, WechatOutlined, SafetyOutlined, ShoppingCartOutlined, AppstoreOutlined, CopyrightOutlined, FormOutlined, NotificationOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {
    const selector = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const [item, setItem] = useState();
    useEffect(() => {
        var user = JSON.parse(selector);
        var menu = [];
        menu.push(getItem('Dashboard', 'dashboard', <HomeOutlined />));
        if (user.RolesId === 1 || user.RolesId === 2) {
            menu.push(getItem('Chat', 'chat', <WechatOutlined />));
        }
        if (user.RolesId === 1) {
            menu.push(getItem('Order', 'order', <ShoppingCartOutlined />));
            menu.push(getItem('Shopify', 'shopify', <AppstoreOutlined />, [
                getItem('Data Kategori', 'category'),
                getItem('Data Produk', 'product'),
            ]));
            menu.push(getItem('Data Master', 'dataMaster', <AppstoreOutlined />, [
                getItem('Data Produk Populer', 'popularProduct'),
                getItem('Data Banner', 'banner'),
                getItem('Data Katalog', 'catalog'),
                getItem('Data Toko', 'store'),
                getItem('Data Promo Toko', 'storePromo'),
                getItem('Data Membership', 'membership'),
                getItem('Data Level Member', 'level'),
            ]));
            menu.push(getItem('E-Warranty', 'ewarranty', <SafetyCertificateOutlined />, [
                getItem('Data Garansi Produk', 'productWarranty'),
                getItem('Data Klaim Garansi', 'warrantyclaim'),
                getItem('Data Garansi Member', 'warrantymember'),
            ]));
            menu.push(getItem('Service Center', 'sc',<ToolOutlined />, [
                getItem('Data Service Center', 'serviceCenter'),
                getItem('Data Service Barang', 'productService'),
                getItem('Data Service Process', 'serviceProcess'),
                getItem('Data Sparepart', 'sparepart'),
            ]));
            menu.push(getItem('FlashSale', 'flashsale', <TagsOutlined />));
            menu.push(getItem('Notifikasi', 'notification', <NotificationOutlined />));
            menu.push(getItem('Syarat Ketentuan', 'privacy', <SafetyOutlined />));
            menu.push(getItem('Data Karyawan', 'users', <TeamOutlined />));
            setItem(menu);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIcon = (icon_name) => {
        switch (icon_name) {
            case 'home':
                return <HomeOutlined />;
            case 'pos':
                return <ShopOutlined />;
            case 'master':
                return <AppstoreOutlined />;
            case 'report':
                return <BookOutlined />;
            case 'team':
                return <TeamOutlined />;
            case 'point':
                return <CopyrightOutlined />;
            case 'settings':
                return <SettingOutlined />;
            case 'membership':
                return <TeamOutlined />
            case 'verify':
                return <FormOutlined />
        }
    }

    return (
        <Sider
            width={200}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                // selectedKeys={['pos']}
                // defaultOpenKeys={['sub1']}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                items={item}
                onClick={({ key }) => navigate('/' + key)}
            />
        </Sider>
    );
}

export default MenuSideBars;